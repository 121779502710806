<template>
    <template v-if="isPlaying">
        <VideoEmbed :src="videoUrl" :params="{autoplay: autoplay}" />
    </template>
    <template v-else>
        <div :style="`background: ${backgroundColor};`"
             class="floating w-24 h-24 absolute rounded-lg z-0 -top-3 -left-3"></div>
        <ResponsiveImage class="rounded-xl z-40 relative" :images="backgroundImage" :alt="title" />
        <button :aria-label="$t('Play')" class="bg-white w-14 h-14 rounded-full flex items-center justify-center absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 focus:outline-none transform transition hover:scale-110 duration-300 ease-in-out z-50"
                @click="onPlay">
            <PlayIcon class="w-5 h-5 ml-1"/>
        </button>
        <div class="bg-secondary-500 w-40 h-40 floating absolute rounded-lg z-10 -bottom-3 -right-3"></div>
    </template>
</template>
<script setup>
import {ref} from 'vue';
import PlayIcon from '@/images/home/play_icon.svg';
import VideoEmbed from '@/Components/FrontEnd/VideoEmbed.vue';
import ResponsiveImage from '@/Components/Common/ResponsiveImage.vue';

defineProps({
    title: {
        type: String,
        required: true,
    },
    backgroundColor: {
        type: String,
        default: '#23BDEE',
    },
    backgroundImage: {
        type: [String, Array],
        required: true,
    },
    videoUrl: {
        type: String,
        required: true,
    },
    autoplay: {
        type: Boolean,
        default: true,
    },
})

const isPlaying = ref(false);

const onPlay = () => {
    isPlaying.value = true;
}
</script>
