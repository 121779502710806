<template>
    <img
            :srcset="srcset"
            sizes="(max-width: 640px) 640px, (max-width: 768px) 768px, (max-width: 1024px) 1024px, (max-width: 1280px) 1280px, 1536px"
            :src="src"
            :alt="alt"
    />
</template>

<script setup>
const props = defineProps({
    images: {
        type: [String, Array],
        required: true,
    },
    placeholder: {
        type: String,
        default: null,
    },
    alt: {
        type: String,
        default: null,
    }
})

const srcset = typeof props.images==='string' ? null:props.images.map((image) => `${Object.values(image)[0]} ${Object.keys(image)[0]}`).join(',')
const src = typeof props.images==='string' ? props.images:props.images.map((image) => Object.values(image)[0]).pop();
</script>
