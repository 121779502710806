<template>
    <div v-if="valid" :class="[css]">
        <iframe loading="lazy"
                sandbox="allow-forms allow-scripts allow-pointer-lock allow-same-origin allow-top-navigation allow-presentation"
                class="embed-responsive-item" allowfullscreen allow="autoplay; fullscreen" :src="url"></iframe>
    </div>
</template>
<script>

export default {
    props: {
        css: {
            type: String,
            default: 'aspect-w-16 aspect-h-9',
        },
        src: {
            type: String,
            required: true,
        },
        params: {
            type: Object,
            default: function() {
                return {autoplay: false };
            },
        },
    },

    data() {
        return {
            valid: false,
            url: '',
            videos: [
                {
                    reg:/^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:\w+\?v=|embed\/|v\/)?)(\w+)(\S+)?$/i,
                    url: 'https://www.youtube.com/embed/$5',
                    params: {
                        'picture-in-picture': 1,
                        accelerometer: 1,
                        gyroscope: 1

                    }
                },
                {
                    reg: /(?:http:|https:|)\/\/(?:player.|www.)?vimeo\.com\/(?:video\/|embed\/|watch\?\S*v=|v\/)?(\d*)/i,
                    url: 'https://player.vimeo.com/video/$1',
                    params: {
                        title: 0,
                        byline: 0,
                        portrait: 0,
                        autoplay: this.params.autoplay ?? 0,
                    },
                },
                {
                    reg: /^.*(?:\/video|dai.ly)\/([A-Za-z0-9]+)([^#&?]*).*/i,
                    url: 'https://www.dailymotion.com/embed/video/$1',
                    params: {
                        autoplay: 0
                    }
                },
                {
                    reg: /^.*coub.com\/(?:embed|view)\/([A-Za-z0-9]+)([^#&?]*).*/i,
                    url: 'https://coub.com/embed/$1',
                    params: {
                        autoplay: 0
                    }
                },
            ],
        }
    },
    watch: {
        src() {
            this.parse();
        },
    },
    mounted() {
        this.parse();
    },
    methods: {
        parse() {
            if (this.src) {
                for (let i = 0; i < this.videos.length; i++) {
                    const v = this.videos[i];
                    let m = v.reg.exec(this.src);
                    if (m) {
                        let params = Object.assign({}, v.params, this.params);
                        let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                        let and = v.url.indexOf('?') >= 0 ? '&':'?';
                        this.url = this.src.replace(v.reg, v.url) + and + query;
                        this.valid = true;
                        return;
                    }
                }
            }
            this.valid = false;
        },
    }
}

</script>
