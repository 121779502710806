<script setup>
import PublicLayout from '@/Layouts/PublicLayout.vue';
import HomeBanner from '@/Components/FrontEnd/HomeBanner.vue';
import HomeCard from '@/Components/FrontEnd/HomeCard.vue';
import CtaButton from '@/Components/FrontEnd/CtaButton.vue';
import IntroText from '@/Components/FrontEnd/IntroText.vue';
import VideoBox from '@/Components/FrontEnd/VideoBox.vue';
import TestimonialBlock from '@/Components/FrontEnd/TestimonialBlock.vue';
import ArrowRightIcon from '@/images/home/arrow_right_icon.svg';
import BillingIcon from '@/images/home/billing.svg';
import ScheduleIcon from '@/images/home/schedule.svg';
import UsersIcon from '@/images/home/users.svg';
import testimonial1Image from '@/images/home/testimonial_1.avif';
import testimonial2Image from '@/images/home/testimonial_2.avif';
import tutorialVideoThumbnailImage640 from '@/images/home/tutorial_video_thumbnail_640w.avif';
import tutorialVideoThumbnailImage768 from '@/images/home/tutorial_video_thumbnail_768w.avif';
import tutorialVideoThumbnailImage1024 from '@/images/home/tutorial_video_thumbnail_1024w.avif';
import tutorialVideoThumbnailImage1280 from '@/images/home/tutorial_video_thumbnail_1280w.avif';
import tutorialVideoThumbnailImage1536 from '@/images/home/tutorial_video_thumbnail_1536w.avif';

defineProps({
    tutorialVideoUrl: {
        type: String,
        required: true,
    },
})

const tutorialImages = [
    {'640w': tutorialVideoThumbnailImage640},
    {'768w': tutorialVideoThumbnailImage768},
    {'1024w': tutorialVideoThumbnailImage1024},
    {'1280w': tutorialVideoThumbnailImage1280},
    {'1536w': tutorialVideoThumbnailImage1536},
]
</script>

<template>
    <PublicLayout title="Home">
        <template #header>
            <HomeBanner :app-name="$page.props.appName" :tutorial-video-url="tutorialVideoUrl"/>
        </template>

        <!-- container -->
        <div class="container pt-5 px-4 lg:px-8 mx-auto max-w-screen-xl text-secondary/70 overflow-x-hidden">

            <!-- trusted by -->
            <!-- <TrustedByBlock /> -->

            <!-- What is -->
            <div data-aos="flip-down">
                <IntroText class="mt-8" :title1="$t('What is')" :title2="$page.props.appName"
                           :description="$t(':app_name is a tool for all users that need to split their expenses with other users, still being able to keep track of their credits and debits over time', {app_name: $page.props.appName.toString()})"/>
            </div>

            <!-- Cards Row -->
            <div class="grid md:grid-cols-3 gap-14 md:gap-5 mt-20">
                <HomeCard :icon="BillingIcon"
                          :title="$t('Input your expenses')"
                          :description="$t('Whether it is a pub bill, a shared rent or a gift, :app_name will handle it', {app_name: $page.props.appName.toString()})"/>
                <HomeCard :icon="UsersIcon" :aos-delay="150" background-color="#F48C06"
                          :title="$t('Split your expenses')"
                          :description="$t(':app_name will automatically split your expenses between participants you chose with a given criteria', {app_name: $page.props.appName.toString()})"/>
                <HomeCard :icon="ScheduleIcon" :aos-delay="300" background-color="#29B9E7"
                          :title="$t('Manage payments')"
                          :description="$t('All participants to a given expense, including you, will add their payments. Each user will get notified when a new payment is done')"/>
            </div>

            <!-- CTA boxes -->
            <!-- <div class="mt-28">
                <div data-aos="fade-up"
                     class="flex flex-col md:flex-row justify-center space-y-5 md:space-y-0 md:space-x-6 lg:space-x-10 mt-7">
                    <div class="relative md:w-5/12">
                        <CtaImageBox :image="testimonial1Image"
                                     title="FOR PRIVATE"
                                     button-text="Learn more"/>
                    </div>
                    <div class="relative md:w-5/12">
                        <CtaImageBox :image="testimonial2Image"
                                     :button-border="false"
                                     button-background="rgba(35, 189, 238, 0.9)"
                                     title="FOR BUSINESS"
                                     button-text="Learn more"/>
                    </div>
                </div>
            </div> -->

            <div class="sm:flex items-center sm:space-x-8 mt-36">
                <div data-aos="fade-right" class="sm:w-1/2 relative">
                    <div
                            class="bg-secondary-500 rounded-full absolute w-12 h-12 z-0 -left-4 -top-3 animate-pulse"></div>
                    <div class="flex items-center space-x-20 mb-5">
                        <span class="border border-gray-300 w-14 absolute"></span>
                        <h1 class="text-secondary/40 tracking-widest text-sm uppercase">{{ $t('Video Tutorial') }}</h1>
                    </div>
                    <h1 class="font-semibold text-2xl relative z-50 text-secondary lg:pr-10">
                        {{ $t('There\'s nothing better than a video tutorial') }}&nbsp;<span class="text-secondary/50">{{
                        $t('to make you understand how :app_name works', { app_name: $page.props.appName.toString() })
                        }}</span></h1>
                    <p class="py-5 lg:pr-32 text-base-content">{{ $t('In few minutes you\'ll be able to master all the features of our application, and if something is not clear or forgotten, you can always watch the video once again!') }}</p>
                </div>
                <div data-aos="fade-left" class="sm:w-1/2 relative mt-10 sm:mt-0">
                    <VideoBox :title="$t('Tutorial')" :background-image="tutorialImages" :video-url="tutorialVideoUrl" />
                </div>
            </div>

            <CtaButton :url="route('pricing')" :text="$t('Discover all the features')" aos="flip-up"
                       extra-class="mt-12 mx-auto block"/>

            <!-- TESTIMONIALS -->
            <div class="mt-24 flex flex-col-reverse md:flex-row items-start md:space-x-10">
                <div data-aos="zoom-in-right" class="md:w-5/12">
                    <div class="flex items-center space-x-20 mb-5">
                        <span class="border border-gray-300 w-14 absolute"></span>
                        <h1 class="text-secondary/40 tracking-widest text-sm uppercase">{{ $t('Testimonials') }}</h1>
                    </div>
                    <h1 class="font-semibold text-darken-500 text-2xl lg:pr-40">{{ $t('What they say about us?') }}</h1>
                    <p class="text-secondary my-5 lg:pr-36">{{
                        $t(':app_name is growing rapidly on the market, gaining the favorable opinion of more and more users.', { app_name: $page.props.appName.toString() })
                        }}</p>
                    <p class="text-secondary my-5 lg:pr-36">
                        {{ $t('Are you one of these or do you have any suggestions for us? Let us know!') }}</p>
                    <button
                            class="flex items-center space-x-3 pl-3 border-b border-l border-t border-secondary-500 text-secondary-500 font-medium my-4 focus:outline-none transform transition hover:scale-110 duration-300 ease-in-out rounded-full">
                        <span>{{ $t('Write your assessment') }}</span>
                        <span
                                class="border border-secondary-500 h-14 w-14 rounded-full flex items-center justify-center">
                            <ArrowRightIcon class="text-primary w-5 h-5"/>
                        </span>
                    </button>
                </div>
                <div data-aos="zoom-in-left" class="lg:w-7/12 flex flex-col justify-between mt-12 space-y-5 lg:mt-0">
                    <div class="flex space-x-5">
                        <TestimonialBlock :image="testimonial1Image" :name="$t('testimonial_1_name')"
                                          :title="$t('testimonial_1_title')"
                                          :description="$t('testimonial_1_description', {app_name: $page.props.appName.toString()})"/>
                    </div>
                    <div class="flex space-x-5">
                        <TestimonialBlock :image="testimonial2Image" :name="$t('testimonial_2_name')"
                                          :title="$t('testimonial_2_title')"
                                          :description="$t('testimonial_2_description', {app_name: $page.props.appName.toString()})"/>
                    </div>
                </div>
            </div>

        </div>
    </PublicLayout>
</template>
